import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Radar, Line } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { FaCog } from "react-icons/fa";
import { subDays } from "date-fns"; // Biblioteka do manipulacji datami
import annotationPlugin from "chartjs-plugin-annotation";
import "chart.js/auto";
import "./UserStats.css";
import { Chart } from "chart.js";
import Page from "../molecules/Page";
Chart.register(annotationPlugin);

const apiUrl = process.env.REACT_APP_API_SOURCE;

const metricNames = {
  elo: "Punkty ELO",
  total_problems: "Zadania ogółem",
  total_problems_solved: "Zadania rozwiązane",
  user_solving_time_spent_minutes: "Czas rozwiązywania (minuty)",
};

const colorList = [
  "#FF5733",
  "#33FF57",
  "#3357FF",
  "#FF33A1",
  "#FFD633",
  "#33FFF2",
  "#F233FF",
  "#33FF9E",
  "#F2333F",
  "#F2FF33",
];

const UserStats = () => {
  const [isRadarChart, setIsRadarChart] = useState(true);
  const [selectedFields, setSelectedFields] = useState([1, 2, 3]);
  const [selectedMetric, setSelectedMetric] = useState("elo");
  const [radarData, setRadarData] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [lineOptions, setLineOptions] = useState(null);
  const [radarOptions, setRadarOptions] = useState(null);
  const [averageElo, setAverageElo] = useState(0);
  const [totalProblems, setTotalProblems] = useState(0);
  const [totalProblemsSolved, setTotalProblemsSolved] = useState(0);
  const [taskCount, setTaskCount] = useState(0);
  const [userJoinedDate, setUserJoinedDate] = useState(0);

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [fieldMapping, setFieldMapping] = useState({});
  const [fetchedData, setFetchedData] = useState(null); // Zmienna dla przechowywania pobranych danych
  const [dateRange, setDateRange] = useState("week");
  const [tempChartType, setTempChartType] = useState("radar");
  const [tempSelectedFields, setTempSelectedFields] = useState(selectedFields);
  const [tempSelectedMetric, setTempSelectedMetric] = useState(selectedMetric);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const panelRef = useRef(null);

  const handleReturnToDashboard = () => {
    navigate("/");
  };

  useEffect(() => {
    const savedFields = JSON.parse(localStorage.getItem("selectedFields"));
    const savedMetric = localStorage.getItem("selectedMetric");

    const savedChartType = localStorage.getItem("chartType");
    if (savedFields) {
      setSelectedFields(savedFields);
      setTempSelectedFields(savedFields);
    }
    if (savedMetric) {
      setSelectedMetric(savedMetric);
      setTempSelectedMetric(savedMetric);
    }
    if (savedChartType) {
      setIsRadarChart(savedChartType === "radar");
      setShowDateRange(savedChartType === "line");
      setTempChartType(savedChartType);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setIsPanelOpen(false);
      }
    };

    // Dodaj nasłuchiwanie kliknięć poza panelem
    document.addEventListener("mousedown", handleClickOutside);

    // Usuwanie nasłuchiwacza po zamknięciu komponentu
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [panelRef]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [fieldsResponse, statsResponse] = await Promise.all([
          axios.get(`${apiUrl}fields_preferences/`, {
            headers: { Authorization: `Token ${token}` },
          }),
          axios.get(`${apiUrl}user_stats_view/`, {
            headers: { Authorization: `Token ${token}` },
          }),
        ]);

        const fields = fieldsResponse.data.user_fields;
        const mapping = {};
        fields.forEach((field) => {
          mapping[field.field_id] = field.field_name;
        });
        setFieldMapping(mapping);

        const data = statsResponse.data;
        setTotalProblems(data.general_stats.total_problems);
        setTotalProblemsSolved(data.general_stats.total_problems_solved);

        const avgElo =
          data.radar_chart.fields.reduce((acc, field) => acc + field.elo, 0) /
          data.radar_chart.fields.length;
        setAverageElo(Math.round(avgElo));

        setTaskCount(
          data.line_chart.reduce((acc, field) => acc + field.elo.length, 0)
        );

        setFetchedData(data); // Przechowywanie danych do dalszego wykorzystania

        // Inicjalizuj wykres radarowy
        updateRadarChart(data, mapping);
        updateLineChart(data, dateRange);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, [token]);

  const updateRadarChart = (data, mapping) => {
    const radarLabels = data.radar_chart.fields
      .filter((field) => selectedFields.includes(field.field_id))
      .map((field) => mapping[field.field_id] || `Field ${field.field_id}`);

    const radarMetricData = data.radar_chart.fields
      .filter((field) => selectedFields.includes(field.field_id))
      .map((field) => field[selectedMetric]);

    setRadarData({
      labels: radarLabels,
      datasets: [
        {
          label: metricNames[selectedMetric],
          data: radarMetricData,
          backgroundColor: "rgba(0, 51, 102, 0.2)", // Ciemnoniebieski z przezroczystością
          borderColor: "rgba(0, 51, 102, 1)", // Ciemnoniebieski (bez przezroczystości)
          borderWidth: 1,
        },
      ],
    });

    setRadarOptions({
      scales: {
        r: {
          ticks: {
            display: true,
            font: {
              size: 12, // Dostosuj rozmiar tekstu w tickach
              weight: "light",
            },
          },
          pointLabels: {
            font: {
              size: 10, // Zmniejszony rozmiar tekstu etykiet
            },
            callback: function (label) {
              // Podziel tekst na linie, jeśli jest dłuższy niż 10 znaków
              const maxLineLength = 10;
              const words = label.split(" ");
              let lines = [];
              let currentLine = words[0];

              for (let i = 1; i < words.length; i++) {
                if ((currentLine + " " + words[i]).length > maxLineLength) {
                  lines.push(currentLine);
                  currentLine = words[i];
                } else {
                  currentLine += " " + words[i];
                }
              }
              lines.push(currentLine);
              return lines;
            },
          },
          // suggestedMin: 0,
          // suggestedMax: 100,
        },
      },
      plugins: {
        legend: {
          display: false, // Ukrycie legendy, aby więcej miejsca było dla wykresu
        },
        datalabels: {
          display: false, // Wyłączenie etykiet danych
        },
      },
      elements: {
        point: {
          radius: 4, // Zwiększenie rozmiaru punktów dla łatwiejszego klikania
          hitRadius: 10,
        },
        line: {
          borderWidth: 2, // Grubość linii na wykresie
        },
      },
      // maintainAspectRatio: false, // Ustawienie, aby wykres miał większy obszar
      // responsive: true,
    });
  };

  const updateLineChart = (data, range) => {
    let interval;
    let rangeDays;
    setLineOptions(null);
    // setSelecte

    switch (range) {
      case "week":
        interval = 1;
        rangeDays = 7;
        break;
      case "twoWeeks":
        interval = 1;
        rangeDays = 14;
        break;
      case "month":
        interval = 7;
        rangeDays = 30;
        break;
      case "threeMonths":
        interval = 7;
        rangeDays = 90;
        break;
      case "year":
        interval = 30;
        rangeDays = 365;
        break;
      default:
        interval = 1;
        rangeDays = 7;
    }

    const today = new Date();
    const minDate = subDays(today, rangeDays);
    const allDates = data.line_chart
      .reduce((acc, field) => {
        field.dates.forEach((date) => {
          if (!acc.includes(date)) acc.push(date);
        });
        return acc;
      }, [])
      .sort();
    // console.log(allDates);

    const selectedDates = allDates
      .filter((date) => new Date(date) >= minDate)
      .filter((_, index) => index % interval === 0);

    const lineDatasets = data.line_chart
      .filter((field) => selectedFields.includes(field.field_id))
      .map((field, index) => {
        const fieldData = [];
        let lastAvailableValue = null;

        selectedDates.forEach((date) => {
          const dateIndex = field.dates.indexOf(date);
          if (dateIndex !== -1) {
            lastAvailableValue = field[selectedMetric][dateIndex];
            fieldData.push(lastAvailableValue);
          } else {
            fieldData.push(lastAvailableValue);
          }
        });

        return {
          label: fieldMapping[field.field_id] || `Field ${field.field_id}`,
          data: fieldData,
          fill: false,
          borderColor: colorList[index % colorList.length],
          tension: 0.1,
        };
      });

    // Date of user registration
    setUserJoinedDate(data.user_joined_date);
    // const userJoinedDate = data.user_joined_date;

    if (
      userJoinedDate >= selectedDates[0] &&
      userJoinedDate <= selectedDates[selectedDates.length - 1]
    ) {
      if (!selectedDates.includes(userJoinedDate)) {
        selectedDates.push(userJoinedDate);
        selectedDates.sort(); // Sortowanie, aby linia była w odpowiednim miejscu
      }
      setLineOptions({
        plugins: {
          datalabels: {
            display: false, // Wyłączenie wyświetlania wartości nad punktami
          },
          legend: {
            labels: {
              // Zmniejszenie rozmiaru czcionki
              font: {
                size: 10, // Możesz dostosować do mniejszych ekranów
              },
              // Użycie małych kropek lub kwadracików zamiast prostokątów
              usePointStyle: true,
              pointStyle: "circle", // Możesz też ustawić 'rect' dla małych kwadracików
              padding: 10, // Mniejsze odstępy między elementami legendy
              boxWidth: 10, // Mniejszy rozmiar kółek
              boxHeight: 10, // Dopasowany rozmiar kółek
            },
            // Umieszczenie legendy na górze lub na dole, aby lepiej dostosować się do urządzeń mobilnych
            position: "top",
          },
          annotation: {
            annotations: {
              joinedDateLine: {
                type: "line",
                xMin: userJoinedDate,
                xMax: userJoinedDate,
                borderColor: "black",
                borderWidth: 1,
                label: {
                  display: true, // Wymusza widoczność etykiety
                  content: "Rejestracja",
                  enabled: true,
                  position: "end",
                  anchor: "end", // Zmieniamy pozycję w górnej części
                  //     position: 'start', // Pozycjonowanie na początku linii (po lewej)
                  // anchor: 'end',     // Ustawienie kotwicy po lewej stronie etykiety
                  xAdjust: -40, // Przesunięcie w lewo o 10 pikseli
                  // yAdjust: 10,
                  color: "black",
                  backgroundColor: "rgba(255, 255, 255, 0.8)", // Jasne tło dla lepszej widoczności
                  font: {
                    size: 12, // Zwiększony rozmiar czcionki
                    // weight: 'bold' // Ustawienie pogrubienia dla lepszej widoczności
                  },
                  // padding: 6, // Dodanie paddingu wokół tekstu
                },
              },
            },
          },
        },
        elements: {
          point: {
            radius: 4, // Mniejszy rozmiar punktów na wykresie
            hitRadius: 7,
            backgroundColor: (context) => context.dataset.borderColor, // Wypełnienie kolorem z danych
            borderWidth: 0, // Brak obramowania dla pełnych kółek
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.2, // Możesz dostosować do uzyskania odpowiedniej wysokości
      });
    } else {
      setLineOptions({
        plugins: {
          legend: {
            labels: {
              // Zmniejszenie rozmiaru czcionki
              font: {
                size: 10, // Możesz dostosować do mniejszych ekranów
              },
              // Użycie małych kropek lub kwadracików zamiast prostokątów
              usePointStyle: true,
              pointStyle: "circle", // Możesz też ustawić 'rect' dla małych kwadracików
              padding: 10, // Mniejsze odstępy między elementami legendy
              boxWidth: 10, // Mniejszy rozmiar kółek
              boxHeight: 10, // Dopasowany rozmiar kółek
            },
            // Umieszczenie legendy na górze lub na dole, aby lepiej dostosować się do urządzeń mobilnych
            position: "top",
          },
          datalabels: {
            display: false, // Wyłączenie wyświetlania wartości nad punktami
          },
          annotation: {
            annotations: {},
          },
        },
        elements: {
          point: {
            radius: 4, // Mniejszy rozmiar punktów na wykresie
            hitRadius: 7,
            backgroundColor: (context) => context.dataset.borderColor, // Wypełnienie kolorem z danych
            borderWidth: 0, // Brak obramowania dla pełnych kółek
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 1.2, // Możesz dostosować do uzyskania odpowiedniej wysokości
      });
    }

    setLineData({
      labels: selectedDates,
      datasets: lineDatasets,
    });
  };

  useEffect(() => {
    if (fetchedData) {
      if (isRadarChart) {
        updateRadarChart(fetchedData, fieldMapping);
      } else {
        updateLineChart(fetchedData, dateRange);
      }
    }
  }, [selectedFields, selectedMetric, tempChartType, dateRange, fetchedData]);

  const handleFieldToggle = (fieldId) => {
    setTempSelectedFields((prevFields) =>
      prevFields.includes(fieldId)
        ? prevFields.filter((id) => id !== fieldId)
        : [...prevFields, fieldId]
    );
  };

  const handleRefreshClick = () => {
    setSelectedFields(tempSelectedFields);
    setSelectedMetric(tempSelectedMetric);
    setIsRadarChart(tempChartType === "radar");
    setShowDateRange(tempChartType === "line");

    // Zapisz wybrane kategorie i typ wykresu do pamięci lokalnej
    localStorage.setItem("selectedFields", JSON.stringify(tempSelectedFields));
    localStorage.setItem("selectedMetric", tempSelectedMetric);
    localStorage.setItem("chartType", tempChartType);

    // Zaktualizuj dane dla wykresów na podstawie nowych ustawień
    if (fetchedData) {
      if (tempChartType === "radar") {
        updateRadarChart(fetchedData, fieldMapping);
      } else {
        updateLineChart(fetchedData, dateRange);
      }
    }
    setIsPanelOpen(false);
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const solvedPercentage = (
    (totalProblemsSolved / totalProblems) *
    100
  ).toFixed(2);
  const solvedColor = `rgb(${255 - 2.55 * solvedPercentage}, ${
    2.55 * solvedPercentage
  }, 0)`;

  return (
    <Page showNav>
      <div className="container mx-auto p-4 relative">
        {/* Kontrolki będą w kontenerze nad wykresem */}
        <div className="flex justify-end items-center controls-wrapper mb-4">
          {showDateRange && (
            <select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="p-2 rounded bg-gray-200 mr-2"
            >
              <option value="week">Ostatni tydzień</option>
              <option value="twoWeeks">Ostatnie 2 tygodnie</option>
              <option value="month">Ostatni miesiąc</option>
              <option value="threeMonths">Ostatnie 3 miesiące</option>
              <option value="year">Ostatni rok</option>
            </select>
          )}
          <FaCog
            onClick={() => setIsPanelOpen(!isPanelOpen)}
            size={24}
            className="icon-cog cursor-pointer"
          />
        </div>

        <h2 className="text-center font-bold text-lg mb-4">
          {metricNames[selectedMetric]}
        </h2>
        <div className="chart-container">
          {isRadarChart && radarData ? (
            <Radar data={radarData} options={radarOptions} />
          ) : (
            lineData && <Line data={lineData} options={lineOptions} />
          )}
        </div>

        {/* Sekcja statystyk */}
        <div className="flex flex-col items-center mb-6 space-y-4">
          <div className="stat-box">Zadania ogółem: {totalProblems}</div>
          <div className="stat-box">
            Zadania rozwiązane: {totalProblemsSolved}
          </div>
          <div className="stat-box">
            Skutecznośc:{" "}
            <span style={{ color: solvedColor }}>{solvedPercentage}%</span>
          </div>
        </div>

        {/* Przycisk powrotu */}
        <div className="flex justify-center mb-6">
          <button
            onClick={handleReturnToDashboard}
            className="btn button-primary"
          >
            Powrót do Dashboardu
          </button>
        </div>

        {/* Panel konfiguracji */}
        {isPanelOpen && (
          <div className="config-panel open" ref={panelRef}>
            <h2 className="text-xl font-bold mb-4">Konfiguracja</h2>
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Wybierz kategorię:</h3>
              {Object.entries(fieldMapping).map(([fieldId, fieldName]) => (
                <div key={fieldId} className="mb-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={tempSelectedFields.includes(parseInt(fieldId))}
                      onChange={() => handleFieldToggle(parseInt(fieldId))}
                      className="mr-2"
                    />
                    {fieldName}
                  </label>
                </div>
              ))}
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Wybierz metrykę:</h3>
              <select
                value={tempSelectedMetric}
                onChange={(e) => setTempSelectedMetric(e.target.value)}
                className="p-2 rounded bg-gray-200 w-full"
              >
                <option value="elo">Punkty ELO</option>
                <option value="total_problems">
                  {metricNames["total_problems"]}
                </option>
                <option value="total_problems_solved">
                  {metricNames["total_problems_solved"]}
                </option>
                <option value="user_solving_time_spent_minutes">
                  {metricNames["user_solving_time_spent_minutes"]}
                </option>
              </select>
            </div>
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">
                Wybierz typ wykresu:
              </h3>
              <select
                value={tempChartType}
                onChange={(e) => setTempChartType(e.target.value)}
                className="p-2 rounded bg-gray-200 w-full"
              >
                <option value="radar">Radarowy</option>
                <option value="line">Liniowy</option>
              </select>
            </div>
            <div className="flex justify-center mt-4">
              <button
                onClick={handleRefreshClick}
                className="btn button-primary w-full"
              >
                Zapisz zmiany
              </button>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default UserStats;

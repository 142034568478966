import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RenderMarkdownWithLatex } from "../../utils/utils";

const ViewSolvedProblem = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const problems = location.state?.problems || [];
  const initialProblemIndex = location.state?.problem_index || 0;
  const [currentProblemIndex, setCurrentProblemIndex] =
    useState(initialProblemIndex);

  const problem = problems[currentProblemIndex];

  const [elapsedTime, setElapsedTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    // Oblicz czas trwania rozwiązywania zadania
    if (problem?.start_time) {
      const updateElapsedTime = () => {
        const currentTime = Math.floor(Date.now() / 1000); // Bieżący czas w sekundach
        const elapsedSeconds = problem.end_time - problem.start_time;

        const hours = Math.floor(elapsedSeconds / 3600);
        const minutes = Math.floor((elapsedSeconds % 3600) / 60);
        const seconds = elapsedSeconds % 60;

        setElapsedTime({ hours, minutes, seconds });
      };

      updateElapsedTime();
    }
  }, [problem]);

  const renderEloChange = (eloChange) => {
    const changeText = eloChange > 0 ? `+${eloChange}` : eloChange;
    const changeColor = eloChange > 0 ? "text-green-500" : "text-red-500";
    return <span className={`text-2xl ${changeColor}`}>{changeText}</span>;
  };

  const renderUserProblemElo = (userElo, problemElo, eloChange) => (
    <div className="flex justify-between items-center gap-4">
      <div className="flex flex-col items-center gap-2">
        <span className="text-6xl flex items-center">
          {userElo}
          {eloChange !== null && (
            <span
              className={`ml-2 text-3xl ${
                eloChange > 0 ? "text-green-600" : "text-red-600"
              }`}
            >
              {eloChange > 0 ? `+${eloChange}` : eloChange}
            </span>
          )}
        </span>
        <span className="text-sm text-slate-400">Twoje ELO</span>
      </div>
      <span>vs</span>
      <div className="flex flex-col items-center gap-2">
        <span className="text-6xl">{problemElo}</span>
        <span className="text-sm text-slate-400">ELO zadania</span>
      </div>
    </div>
  );

  const renderAnswers = (answers, trueAnswer, selectedAnswer) => (
    <ul className="grid grid-cols-2 gap-4">
      {answers.map((answer, index) => (
        <li key={index}>
          <button
            className={`btn w-full !max-w-none border border-slate-300 relative text-black bg-white ${
              answer.isCorrect
                ? "!bg-green-100 !text-green-600"
                : answer.text === selectedAnswer
                ? "!bg-red-100 !text-red-600"
                : "!text-black"
            }`}
            disabled
          >
            <RenderMarkdownWithLatex text={answer.text} />
          </button>
        </li>
      ))}
    </ul>
  );

  const goToPreviousProblem = () => {
    const previousIndex =
      (currentProblemIndex - 1 + problems.length) % problems.length;
    setCurrentProblemIndex(previousIndex);
  };

  const goToNextProblem = () => {
    const nextIndex = (currentProblemIndex + 1) % problems.length;
    setCurrentProblemIndex(nextIndex);
  };

  return (
    <div className="flex flex-col justify-center items-center gap-5">
      <div className="flex justify-between w-full max-w-3xl px-4">
        <button
          onClick={goToPreviousProblem}
          className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
        >
          &lt;
        </button>
        <button
          onClick={goToNextProblem}
          className="btn px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
        >
          &gt;
        </button>
      </div>
      <p>
        Czas rozwiązania zadania:
        {elapsedTime.hours > 0 && ` ${elapsedTime.hours}h`}
        {elapsedTime.minutes > 0 && ` ${elapsedTime.minutes}m`}
        {` ${elapsedTime.seconds}s`}
      </p>
      {/* <h2 className="text-2xl font-semibold text-gray-600">Szczegóły rozwiązania zadania</h2> */}
      {renderUserProblemElo(
        problem.user_elo,
        problem.problem_elo,
        problem.elo_change
      )}
      <div className="flex flex-col gap-2 w-full max-w-xl text-center text-balance text-xl">
        <RenderMarkdownWithLatex text={problem.question} />
        {renderAnswers(
          problem.answers,
          problem.true_answer,
          problem.selected_answer
        )}
      </div>
      <div className="flex flex-col items-center gap-2 w-full max-w-xl mt-2">
        <h3 className="text-xl font-semibold text-gray-500">Rozwiązanie</h3>
        <RenderMarkdownWithLatex text={problem.solution} />
      </div>

      {/* Sekcja z dodatkowymi statystykami zadania */}
      <div className="flex flex-col items-center gap-4 w-full max-w-xl mt-4">
        {/* <h3 className="text-xl font-semibold text-gray-500">Statystyki zadania</h3> */}
        {/* <p>Czas rozwiązania zadania: 
          {elapsedTime.hours > 0 && ` ${elapsedTime.hours}h`}
          {elapsedTime.minutes > 0 && ` ${elapsedTime.minutes}m`}
          {` ${elapsedTime.seconds}s`}
        </p> */}

        <div className="flex items-center gap-4">
          <p>
            <span className="font-semibold">Podpowiedź: </span>
            {problem.is_hint_used ? "❌ Wyświetlono" : "✅ Nie wyświetlono"}
          </p>
          <p>
            <span className="font-semibold">Rozwiązanie: </span>
            {problem.is_solution_viewed
              ? "❌ Wyświetlono"
              : "✅ Nie wyświetlono"}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center gap-4 w-full max-w-xl mt-4">
        <button
          onClick={() => navigate("/session-stats")}
          className="btn w-full !max-w-none border border-slate-300 hover:bg-blue-100 text-black bg-transparent"
        >
          Powrót do statystyk sesji
        </button>
      </div>
    </div>
  );
};

export default ViewSolvedProblem;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_SOURCE;

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // Nowe pole
  const [language, setLanguage] = useState("pl");
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [error, setError] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});
  const [passwordHints, setPasswordHints] = useState([]); // Dodane do wskazówek
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await axios.get(`${apiUrl}all_fields/`);
        setFields(response.data.all_fields);
        setAvailableFields(
          response.data.all_fields.filter((field) => field.lang === language)
        );
      } catch (error) {
        setError("Nie udało się pobrać dziedzin.");
      }
    };
    fetchFields();
  }, [language]);

  const validatePassword = (password) => {
    const hints = [];
    if (password.length < 8) {
      hints.push("Hasło musi mieć co najmniej 8 znaków.");
    }
    if (!/[A-Z]/.test(password)) {
      hints.push("Hasło musi zawierać co najmniej jedną wielką literę.");
    }
    if (!/[a-z]/.test(password)) {
      hints.push("Hasło musi zawierać co najmniej jedną małą literę.");
    }
    if (!/[0-9!@#$%^&*(),.?":{}|<>]/.test(password)) {
      hints.push(
        "Hasło musi zawierać co najmniej jedną cyfrę lub znak specjalny."
      );
    }
    setPasswordHints(hints);
    return hints.length ? hints.join(" ") : null;
  };

  const handleFieldChange = (fieldId) => {
    setSelectedFields((prev) =>
      prev.includes(fieldId)
        ? prev.filter((id) => id !== fieldId)
        : [...prev, fieldId]
    );
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(null);
    setFieldErrors({});

    // Sprawdzenie zgodności haseł
    if (password !== confirmPassword) {
      setFieldErrors((prev) => ({
        ...prev,
        confirmPassword: "Hasła nie są zgodne.",
      }));
      return;
    }

    // Sprawdzenie hasła na froncie
    const passwordError = validatePassword(password);
    if (passwordError) {
      setFieldErrors((prev) => ({ ...prev, password: passwordError }));
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}register_user/`, {
        username,
        email,
        password,
        lang: language,
        selected_fields: selectedFields,
      });

      if (response.status === 201) {
        navigate("/login");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const responseErrors = error.response.data;
        const newFieldErrors = {};
        if (responseErrors.username) {
          newFieldErrors.username = responseErrors.username.join(" ");
        }
        if (responseErrors.email) {
          newFieldErrors.email = responseErrors.email.join(" ");
        }
        if (responseErrors.password) {
          newFieldErrors.password = responseErrors.password.join(" ");
        }
        if (responseErrors.selected_fields) {
          newFieldErrors.selected_fields =
            responseErrors.selected_fields.join(" ");
        }
        setFieldErrors(newFieldErrors);
      } else {
        setError("Rejestracja nie powiodła się. Spróbuj ponownie później.");
      }
    }
  };

  return (
    <div className="flex flex-col justify-center items-center gap-4 w-full max-w-xs mx-auto">
      <h1>Rejestracja</h1>
      <form className="flex flex-col gap-4 w-full" onSubmit={handleRegister}>
        <div className="flex flex-col gap-1">
          <label className="font-medium">Nazwa użytkownika:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="p-2 rounded border border-slate-300"
          />
          {fieldErrors.username && (
            <p className="text-red-500">{fieldErrors.username}</p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-medium">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="p-2 rounded border border-slate-300"
          />
          {fieldErrors.email && (
            <p className="text-red-500">{fieldErrors.email}</p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-medium">Hasło:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e.target.value);
            }}
            required
            className="p-2 rounded border border-slate-300"
          />
          {/* Wskazówki dotyczące hasła */}
          {passwordHints.length > 0 && (
            <ul className="text-slate-500 text-sm list-disc ml-5">
              {passwordHints.map((hint, index) => (
                <li key={index}>{hint}</li>
              ))}
            </ul>
          )}
          {fieldErrors.password && (
            <p className="text-red-500">{fieldErrors.password}</p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-medium">Potwierdź hasło:</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="p-2 rounded border border-slate-300"
          />
          {fieldErrors.confirmPassword && (
            <p className="text-red-500">{fieldErrors.confirmPassword}</p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-medium">Język:</label>
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            className="p-2 rounded border border-slate-300"
          >
            <option value="pl">Polski</option>
            <option value="en">Angielski</option>
          </select>
          {fieldErrors.lang && (
            <p className="text-red-500">{fieldErrors.lang}</p>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label className="font-medium">Wybierz dziedziny:</label>
          {availableFields.map((field) => (
            <div key={field.field_id} className="mb-2">
              <input
                type="checkbox"
                id={`field-${field.field_id}`}
                checked={selectedFields.includes(field.field_id)}
                onChange={() => handleFieldChange(field.field_id)}
              />
              <label htmlFor={`field-${field.field_id}`} className="ml-2">
                {field.field_name}
              </label>
            </div>
          ))}
          {fieldErrors.selected_fields && (
            <p className="text-red-500">{fieldErrors.selected_fields}</p>
          )}
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button type="submit" className="btn mx-auto">
          Zarejestruj
        </button>
      </form>
      <p className="text-center">
        Masz już konto?{" "}
        <a
          href="/login"
          className="text-blue-600 hover:text-blue-800 hover:underline"
        >
          Zaloguj się
        </a>
      </p>
    </div>
  );
};

export default Register;

import React, { useState } from "react";
import Page from "../molecules/Page";
import PasswordInput from "../atoms/PasswordInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_SOURCE; // Zmienna środowiskowa z adresem API

const Settings = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleLogout = async () => {
    try {
      await axios.post(
        `${apiUrl}user_logout/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if (newPassword !== confirmPassword) {
      setError("Nowe hasła muszą być takie same.");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch(`${apiUrl}change_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`, // Jeśli jest wymagana autoryzacja, dodajemy token
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      });

      if (response.ok) {
        setMessage("Hasło zostało zmienione pomyślnie.");
      } else {
        const errorData = await response.json();
        setError(errorData?.message || "Wystąpił błąd. Spróbuj ponownie.");
      }
    } catch (err) {
      setError("Błąd połączenia. Sprawdź swoje połączenie i spróbuj ponownie.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Page showNav>
                <button
              onClick={handleLogout}
              className="btn bg-red-500 hover:bg-red-700"
            >
              Wyloguj się
            </button>
      <div style={{ maxWidth: "400px", margin: "0 auto", textAlign: "center" }}>

        <h2>Zmień hasło</h2>
        {message && <p style={{ color: "green" }}>{message}</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}
        <form onSubmit={handleSubmit}>
          <PasswordInput
            id="old-password"
            label_content="Stare hasło"
            value={oldPassword}
            onChange={handleOldPasswordChange}
          />
          <PasswordInput
            id="new-password"
            label_content="Nowe hasło"
            value={newPassword}
            onChange={handleNewPasswordChange}
          />
          <PasswordInput
            id="confirm-password"
            label_content="Potwierdź nowe hasło"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
          <button
            type="submit"
            disabled={isSubmitting}
            className="btn"
            style={{
              padding: "10px 20px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: isSubmitting ? "not-allowed" : "pointer",
            }}
          >
            {isSubmitting ? "Wysyłanie..." : "Zmień hasło"}
          </button>
        </form>
      </div>
    </Page>
  );
};

export default Settings;

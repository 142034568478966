import React from "react";
import NavTestPage from "./NavTestPage";

const PageTestPage = (props) => {
  const { showNav, children } = props;

  return (
    <div className="flex flex-col">
      {showNav && <NavTestPage />}
      <div
        className={`flex-1 flex flex-col justify-center items-center gap-10 ${
          showNav && "max-sm:pb-20"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default PageTestPage;

import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import Settings from "./components/pages/Settings";
import Dashboard from "./components/pages/Dashboard";
import SolveProblem from "./components/pages/SolveProblem";
import EndSessionStats from "./components/pages/EndSessionStats";
import SessionStats from "./components/pages/SessionStats";
import ViewSolvedProblem from "./components/pages/ViewSolvedProblem";
import UserStats from "./components/pages/UserStats";
import FieldsPreferences from "./components/pages/FieldsPreferences"; 
import PasswordReset from "./components/pages/PasswordReset";
import PasswordResetConfirm from "./components/pages/PasswordResetConfirm";

const App = () => {
  const [problemsData, setProblemsData] = useState([]); // Stan do przechowywania listy problemów
  const [userEloDict, setUserEloDict] = useState({}); // Stan do przechowywania średniego ELO
  const [problemsSession, setProblemsSession] = useState([]);
  const [problem, setProblem] = useState(null);
  const [sessionStartTime, setSessionStartTime] = useState(null);

  return (
    <div className="grid gap-4 min-h-screen max-w-screen-2xl p-4 mx-auto">
      <Router>
        <Routes>
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/end-session-stats"
            element={<EndSessionStats
            //  problemsSession={problemsSession} 
             />
             }
          />
                    <Route
            path="/session-stats"
            element={<SessionStats 
            // problemsSession={problemsSession} 
            // sessionStartTime={sessionStartTime}
            />
            }
          />
          <Route
            path="/view-solved-problem"
            element={<ViewSolvedProblem problem={problem} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password_reset" element={<PasswordReset />} />
          <Route
            path="/password_reset/confirm"
            element={<PasswordResetConfirm />}
          />
          {/* Dashboard as the default route */}
          <Route
            path="/"
            element={
              <Dashboard
                setProblemsData={setProblemsData} // Przekazanie funkcji do aktualizacji problemów
                setUserEloDict={setUserEloDict} // Przekazanie funkcji do aktualizacji średniego ELO
              />
            }
          />
          {/* Route for User Stats */}
          <Route path="/user-stats" element={<UserStats />} />
          {/* Route for Solve Problem */}
          <Route
            path="/solve-problem"
            element={
              <SolveProblem
                // problemsData={problemsData} // Przekazanie problemów do SolveProblem
                // userEloDict={userEloDict} // Przekazanie średniego ELO do SolveProblem
                // setUserEloDict={setUserEloDict}
                // setProblemsData={setProblemsData}
              />
            }
          />
          {/* Route for Fields Preferences */}
          <Route path="/fields-preferences" element={<FieldsPreferences />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

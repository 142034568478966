import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Page from "../molecules/Page";

const apiUrl = process.env.REACT_APP_API_SOURCE;

const FieldsPreferences = () => {
  const [userFields, setUserFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFieldId, setSelectedFieldId] = useState("");
  const [showHiddenFields, setShowHiddenFields] = useState(false);
  const [localChanges, setLocalChanges] = useState([]);
  const navigate = useNavigate();
  const handleBackToDashboard = () => {
    navigate("/");
    return;
  };

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await axios.get(`${apiUrl}fields_preferences/`, {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        });
        setUserFields(response.data.user_fields);
        setAvailableFields(response.data.available_fields);
      } catch (error) {
        console.error("Error fetching fields:", error);
      }
    };

    fetchFields();
  }, []);

  // Ukrywanie/Przywracanie dziedziny i zapisywanie obu parametrów do lokalnych zmian
  const toggleFieldVisibility = (fieldId, currentElo) => {
    const updatedFields = userFields.map((field) =>
      field.field_id === fieldId
        ? { ...field, is_visible: !field.is_visible }
        : field
    );
    setUserFields(updatedFields);

    const updatedVisibility = !userFields.find(
      (field) => field.field_id === fieldId
    ).is_visible;

    addToLocalChanges(fieldId, {
      user_field_elo: currentElo,
      is_visible: updatedVisibility,
    });
  };

  // Dodawanie nowej dziedziny
  const handleAddField = (e) => {
    e.preventDefault();
    const newField = availableFields.find(
      (field) => field.field_id === parseInt(selectedFieldId)
    );
    if (newField) {
      setUserFields([
        ...userFields,
        { ...newField, user_field_elo: 1000, is_visible: true },
      ]);
      setAvailableFields(
        availableFields.filter(
          (field) => field.field_id !== parseInt(selectedFieldId)
        )
      );
      addToLocalChanges(parseInt(selectedFieldId), {
        user_field_elo: 1000,
        is_visible: true,
      });
    }
  };

  // Dodawanie zmian do lokalnych zmian
  const addToLocalChanges = (fieldId, changes) => {
    const updatedChanges = localChanges.filter(
      (item) => item.field_id !== fieldId
    );
    setLocalChanges([...updatedChanges, { field_id: fieldId, ...changes }]);
  };

  // Zapisanie zmian
  const handleSaveChanges = async () => {
    try {
      await axios.post(
        `${apiUrl}update_user_fields/`,
        {
          user_fields: localChanges,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      setLocalChanges([]);
      alert("Zmiany zapisane pomyślnie.");
    } catch (error) {
      console.error("Błąd podczas zapisywania zmian:", error);
    }
  };

  return (
    <Page showNav>
      <div className="container space-y-4">
        <h1>Zarządzanie Polami</h1>

        <h2>Twoje Pola</h2>
        {userFields
          .filter((userField) => showHiddenFields || userField.is_visible)
          .map((userField) => (
            <div key={userField.field_id} className="field-item space-y-2">
              <h3>
                {userField.field_name} - Poziom ELO: {userField.user_field_elo}
              </h3>
              <div className="field-details grid gap-1">
                Nauka: {userField.science}
                <br />
                Dziedzina: {userField.domain}
                <br />
                Poziom Wiedzy: {userField.knowledge_level}
                <br />
                Status: {userField.is_visible ? "Widoczne" : "Ukryte"}
              </div>
              <div className="flex gap-4">
                {userField.is_visible ? (
                  <button
                    className="btn"
                    onClick={() =>
                      toggleFieldVisibility(
                        userField.field_id,
                        userField.user_field_elo
                      )
                    }
                  >
                    Ukryj
                  </button>
                ) : (
                  <button
                    className="btn"
                    onClick={() =>
                      toggleFieldVisibility(
                        userField.field_id,
                        userField.user_field_elo
                      )
                    }
                  >
                    Przywróć
                  </button>
                )}
              </div>
            </div>
          ))}

        <button
          className="btn"
          onClick={() => setShowHiddenFields(!showHiddenFields)}
        >
          {showHiddenFields
            ? "Ukryj ukryte dziedziny"
            : "Pokaż ukryte dziedziny"}
        </button>

        <h2>Dodaj Nowe Pole</h2>
        <form className="flex gap-4" onSubmit={handleAddField}>
          <label htmlFor="field_id">Wybierz pole:</label>
          <select
            className="rounded border border-slate-300"
            id="field_id"
            value={selectedFieldId}
            onChange={(e) => setSelectedFieldId(e.target.value)}
          >
            <option value="">-- Wybierz pole --</option>
            {availableFields.map((field) => (
              <option key={field.field_id} value={field.field_id}>
                {field.field_name} - {field.science} - {field.domain}
              </option>
            ))}
          </select>
          <button className="btn" type="submit">
            Dodaj
          </button>
        </form>

        <button onClick={handleSaveChanges} className="btn">
          Zapisz zmiany
        </button>
        <button onClick={handleBackToDashboard} className="btn">
          Powrót do Dashboardu
        </button>
      </div>
    </Page>
  );
};

export default FieldsPreferences;

import usePasswordToggle from "../../utils/usePasswordToggle";

const PasswordInput = (props) => {
  const { id, label_content, value, onChange } = props;
  const [InputType, Icon] = usePasswordToggle();

  return (
    <div className="flex flex-col gap-1">
      <label for={id} className="max-w-max font-medium">
        {label_content}
      </label>
      <div className="relative">
        <input
          id={id}
          className="w-full p-2 pr-12 rounded border border-slate-300"
          type={InputType}
          value={value}
          onChange={onChange}
          required
        />
        {Icon}
      </div>
    </div>
  );
};

export default PasswordInput;

import React, { useState } from "react";

const apiUrl = process.env.REACT_APP_API_SOURCE;

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");
    setError("");

    try {
      const response = await fetch(`${apiUrl}password_reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("E-mail z linkiem do zresetowania hasła został wysłany.");
      } else {
        const errorData = await response.json();
        setError(
          errorData?.message ||
            "Wystąpił błąd. Upewnij się, że użytkownik o podanym adresie istnieje i spróbuj ponownie."
        );
      }
    } catch (err) {
      setError("Błąd połączenia. Sprawdź swoje połączenie i spróbuj ponownie.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto", textAlign: "center" }}>
      <h2>Resetowanie hasła</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Podaj swój adres e-mail:</label>
          <input
            className="rounded border border-slate-300"
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
            style={{ width: "100%", padding: "10px", margin: "10px 0" }}
          />
        </div>
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn"
          style={{
            padding: "10px 20px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: isSubmitting ? "not-allowed" : "pointer",
          }}
        >
          {isSubmitting ? "Wysyłanie..." : "Zresetuj hasło"}
        </button>
      </form>
      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default PasswordReset;

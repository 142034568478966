import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Page from "../molecules/Page";

Chart.register(ChartDataLabels);

const EndSessionStats = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const problemsSession =
    JSON.parse(localStorage.getItem("problemsSession")) || [];
  const [fieldMapping, setFieldMapping] = useState({});

  console.log('problemssession ',problemsSession);

  useEffect(() => {
    const availableFields =
      JSON.parse(localStorage.getItem("available_fields")) || [];
    const mapping = availableFields.reduce((acc, field) => {
      acc[field.field_id] = field.field_name;
      return acc;
    }, {});
    setFieldMapping(mapping);
  }, []);

  const totalProblems = problemsSession.length;
  const correctProblems = problemsSession.filter((p) => p.is_solved).length;
  const incorrectProblems = totalProblems - correctProblems;
  const successRate =
    totalProblems > 0
      ? ((correctProblems / totalProblems) * 100).toFixed(2)
      : "0.00";
  const averageTime =
    totalProblems > 0
      ? (
          problemsSession.reduce(
            (sum, p) => sum + (p.end_time - p.start_time),
            0
          ) / totalProblems
        ).toFixed(2)
      : "0.00";

  const categories = [...new Set(problemsSession.map((p) => p.field_id))];

  const categoryEloChange = problemsSession.reduce((acc, problem) => {
    acc[problem.field_id] = (acc[problem.field_id] || 0) + problem.elo_change;
    return acc;
  }, {});

  const chartData = {
    labels: categories.map((category) => fieldMapping[category] || category),
    datasets: [
      {
        label: "Rozwiązane",
        data: categories.map(
          (category) =>
            problemsSession.filter(
              (p) => p.field_id === category && p.is_solved
            ).length
        ),
        backgroundColor: "green",
      },
      {
        label: "Nierozwiązane",
        data: categories.map(
          (category) =>
            problemsSession.filter(
              (p) => p.field_id === category && !p.is_solved
            ).length
        ),
        backgroundColor: "red",
      },
    ],
  };

  return (
    <Page showNav>
      <section className="flex flex-col md:flex-row md:justify-between items-start md:items-center mb-8">
        <div className="mb-6 md:mb-0">
          <h2 className="text-2xl font-semibold mb-4">Ogólne statystyki</h2>
          <p className="mb-2">
            Liczba prób: <strong>{totalProblems}</strong>
          </p>
          <p className="mb-2">
            Skuteczność: <strong>{successRate}%</strong>
          </p>
          <p className="mb-2">
            Średni czas na zadanie: <strong>{averageTime} sekund</strong>
          </p>
        </div>
        <div style={{ width: "100%", maxWidth: "400px" }}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: { display: false, position: "top" },
                datalabels: {
                  color: "white",
                },
              },
              scales: {
                x: { stacked: true },
                y: {
                  stacked: true,
                  ticks: { stepSize: 1 },
                },
              },
            }}
          />
        </div>
      </section>

      <section>
        {categories.map((category, index) => (
          <CategoryStatistics
            key={index}
            category={fieldMapping[category] || category}
            problems={problemsSession.filter((p) => p.field_id === category)}
            eloChange={categoryEloChange[category] || 0}
          />
        ))}
      </section>

      <div className="mt-8 flex justify-center">
        <button
          onClick={() => navigate("/")}
          className="btn px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Powrót do Dashboardu
        </button>
      </div>
    </Page>
  );
};

const CategoryStatistics = ({ category, problems, eloChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const totalCategoryProblems = problems.length;
  const correctCategoryProblems = problems.filter((p) => p.is_solved).length;
  const incorrectCategoryProblems =
    totalCategoryProblems - correctCategoryProblems;
  const successRate =
    totalCategoryProblems > 0
      ? ((correctCategoryProblems / totalCategoryProblems) * 100).toFixed(2)
      : "0.00";
  const averageTime =
    totalCategoryProblems > 0
      ? (
          problems.reduce((sum, p) => sum + (p.end_time - p.start_time), 0) /
          totalCategoryProblems
        ).toFixed(2)
      : "0.00";

  const stackedChartData = {
    labels: [category],
    datasets: [
      {
        label: "Rozwiązane",
        data: [correctCategoryProblems],
        backgroundColor: "green",
      },
      {
        label: "Nierozwiązane",
        data: [incorrectCategoryProblems],
        backgroundColor: "red",
      },
    ],
  };

  const sign = eloChange > 0 ? "+" : "";
  const eloColorClass =
    eloChange > 0
      ? "text-green-500"
      : eloChange < 0
      ? "text-red-500"
      : "text-gray-700";

  const handleProblemClick = (problems, problem_index) => {
    console.log(problem_index);
    navigate("/view-solved-problem", { state: { problems, problem_index } });
  };

  const renderSessionStatus = (problems) => {
    if (problems.length === 0) return null;
    return (
      <div className="flex gap-2 max-w-lg relative items-center">
        <div className="grid grid-cols-10 gap-2">
          {problems.map((problem, index) => (
            <div
              key={index}
              onClick={() => handleProblemClick(problems, index)}
              className={`flex justify-center items-center w-8 h-8 p-1 rounded text-white text-lg ${
                problem.is_solved ? "bg-green-600" : "bg-red-600"
              }`}
            >
              {index + 1}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="mb-6">
      <h3
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center cursor-pointer text-xl font-medium"
        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
      >
        <span className="mr-2">{isExpanded ? "v" : ">"}</span>
        {category}{" "}
        <span className={`${eloColorClass} ml-2`}>
          {" "}
          {sign}
          {eloChange}{" "}
        </span>
      </h3>
      {isExpanded && (
        <div className="ml-6 mt-2">
          <p className="mb-1">
            Liczba prób: <strong>{totalCategoryProblems}</strong>
          </p>
          <p className="mb-1">
            Skuteczność: <strong>{successRate}%</strong>
          </p>
          <p className="mb-4">
            Średni czas na zadanie: <strong>{averageTime} sekund</strong>
          </p>
          <p className="mb-4">
            Zmiana ELO:{" "}
            <span className={eloColorClass}>
              {sign}
              {eloChange}
            </span>
          </p>
          <div className="grid gap-2 max-w-lg">
            {renderSessionStatus(problems)}
          </div>
        </div>
      )}
    </div>
  );
};

export default EndSessionStats;
